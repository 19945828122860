'use client'

import React from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ProviderCompose} from '@paji-sdk/web'

import {AppCtxProvider} from '../../../contexts/appCtx'
import {DeveloperModeProvider} from '../../../contexts/developerMode'
import {DarkModeProvider} from '../../../hooks/darkMode'
import {ThemeProvider} from '../../../hooks/theme'
import {NotificationsProvider} from '../../../hooks/notifications'
import MuiAppRouterCacheProvider from './MuiAppRouterCacheProvider'


const queryClient = new QueryClient()

export default function ClientProvider({appCtx, children}) {
  return (
    <ProviderCompose.Composer providers={[
      ProviderCompose.provider(MuiAppRouterCacheProvider),
      ProviderCompose.provider(QueryClientProvider, {client: queryClient}),
      ProviderCompose.provider(AppCtxProvider, {appCtx}),
      ProviderCompose.provider(DeveloperModeProvider),
      ProviderCompose.provider(DarkModeProvider),
      ProviderCompose.provider(ThemeProvider),
      ProviderCompose.provider(NotificationsProvider),
    ]}>
      {children}
    </ProviderCompose.Composer>
  )
}
